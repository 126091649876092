<template>
  <b-modal
    id="modal-share-model"
    title="Manage model access"
    size="lg"
    lazy
    no-close-on-backdrop
    no-close-on-esc
    @show="onShow"
    @cancel="onReset"
    @hidden="onReset"
  >
    <p class="">
      Users added will be granted read/write permissions to the
      <span class="font-weight-bold text-primary">{{ $store.state.model.name }}</span> model
    </p>
    <validation-observer ref="usernameValidator">
      <b-form-group>
        <label>Share model with a user</label>
        <validation-provider
          #default="{ errors }"
          name="username"
          rules="required"
        >
          <div class="d-inline-flex w-100">
              <b-form-input
                id="share-username"
                v-model="txtUsername"
                type="text"
                placeholder="Enter username"
                class="mr-50"
                :state="errors.length > 0 ? false:null"
                @keydown.enter="shareWithUser"
              />
              <b-button
                :disabled="txtUsername.length === 0"
                variant="flat-success"
                size="sm"
                class="d-inline-flex align-items-center"
                title="Share model with username (Shortcut key: Enter)"
                @click="shareWithUser"
              >
                <feather-icon icon="UserPlusIcon" class="mr-50" />
                <span>Share</span>
              </b-button>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>

    <hr>

    <b-form-group class="mh-25 overflow-y-scroll">
      <LabelWithHoverIcon label-string="Shared with" class="mb-25" />
      <b-list-group v-if="sharedWithList.length" id="model-shared">
        <b-list-group-item
          v-for="(userId, index) in sharedWithList"
          :key="index"
          class="d-flex justify-content-between align-items-center py-25"
        >
          {{ getUserUsername(userId) }}
          <b-button
            class="ml-auto"
            size="sm"
            variant="flat-danger"
            :title="`Stop sharing model with ${getUserUsername(userId)}`"
            @click="unshare(getUserUsername(userId))"
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            />
          </b-button>
        </b-list-group-item>
      </b-list-group>
      <p v-else class="ml-25 text-muted">
        No-one
      </p>
    </b-form-group>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-primary"
        :disabled="loading"
        @click="cancel"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  onMounted,
  ref,
  computed,
  watch,
  nextTick,
} from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import store from '@/store'
import router from '@/router'

export default {
  name: 'ShareModelWithUsers',
  components: {
    LabelWithHoverIcon,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    model: {
      type: String,
      required: false,
      default: '',
    },
    snapshot: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const modelId = computed(() => (props.model ? props.model : router.currentRoute.params.modelId))
    const snapshot = computed(() => (props.snapshot ? props.snapshot : false))
    const loading = ref(false)
    const txtUsername = ref('')
    const sharedWithList = ref([])

    const onShow = async () => {
      // Keep this
      await nextTick()
      txtUsername.value = ''
      await loadShareList()
    }
    const loadShareList = async () => {
      loading.value = true
      await setXTarget()
      sharedWithList.value = []
      coreService.modelApi.getUsersSharedWith(modelId.value)
        .then(response => {
          sharedWithList.value = response
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch shared user list',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          loading.value = false
          unsetXTarget()
        })
    }
    const shareWithUser = () => {
      if (!txtUsername.value) { return }

      loading.value = true
      setXTarget()
      coreService.post(`/v1/model/${modelId.value}/shared_with/${txtUsername.value.trim()}`)
        .then(response => {
          txtUsername.value = ''
          usernameValidator.value.reset()
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Access granted',
              text: response.data,
              icon: 'UserPlusIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to share model with user',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          loading.value = false
          loadShareList()
        })
    }
    const unshare = username => {
      loading.value = true
      setXTarget()
      coreService.modelApi.unshareModelFromUser(modelId.value, username)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Access revoked',
              text: `Model is no longer shared with ${username}`,
              icon: 'UserMinusIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to revoke access',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          loading.value = false
          loadShareList()
        })
    }

    const usernameValidator = ref(null)
    const validateUsername = () => {
      usernameValidator.value.validate()
        .then(success => {
          if (success) {
            coreService.usersManagementApi.checkUsernameExists(txtUsername.value)
              .then(response => {
                if (response) {
                  this.shareModel()
                } else {
                  context.root.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error sharing model',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: 'Failed to share model (User does not exist)',
                    },
                  })
                  usernameValidator.value.setErrors({
                    Email: ['User does not exist.'],
                  })
                }
              })
          }
        })
    }

    const onReset = () => {
      unsetXTarget()
    }

    const setXTarget = () => {
      // We need to do this if we have entered from the model browser - else skip
      if (snapshot.value === true) {
        sessionStorage.setItem('X-Target', `snapshot-${modelId.value}`)
      } else {
        unsetXTarget()
      }
    }

    const unsetXTarget = () => {
      if (snapshot.value === true) {
        sessionStorage.removeItem('X-Target')
        store.state.model.database = null
      }
    }

    return {
      loading,
      txtUsername,
      sharedWithList,
      usernameValidator,

      onShow,
      shareWithUser,
      unshare,
      modelId,
      onReset,
    }
  },
}
</script>
