<template>
  <b-modal
    id="manage-model-owners-modal"
    size="lg"
    :title="loggedInUserIsAModelOwner ? 'Manage Model Owners' : 'View Model Owners'"
    ok-title="Close"
    ok-only
    @show="fetchExistingOwners"
    @hidden="onReset"
  >
    <p>
      Model ownership allows a user to manage the sharing permissions of a model as well as granting them
      read/write access to all the data within it.
    </p>
    <b-form-group v-if="loggedInUserIsAModelOwner" label="Add a model owner">
      <b-input-group>
        <b-form-input
          v-model="newOwner"
          placeholder="Enter a username"
          autocomplete="off"
          autocapitalize="off"
          :disabled="loadingAddRemoveOwner || !loggedInUserIsAModelOwner"
          @keyup.enter="addOwner"
        />
        <b-input-group-append>
          <b-button
            variant="outline-success"
            :disabled="!loggedInUserIsAModelOwner"
            @click="addOwner"
          >
            <feather-icon
              icon="UserPlusIcon"
              class="text-success font-small"
            />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <hr>

    <div class="d-inline-flex w-100 justify-content-between">
      <h5 class="text-primary">Current owners</h5>
      <b-button
        title="Refresh model owner list"
        variant="flat-info"
        size="sm"
        @click="fetchExistingOwners"
      >
        <feather-icon icon="RefreshCwIcon" />
      </b-button>
    </div>
    <div v-if="!loadingFetchOwners">
      <b-list-group v-if="existingOwners.length" id="model-shared">
        <b-list-group-item
          v-for="(userId, index) in existingOwners"
          :key="index"
          class="d-flex justify-content-between align-items-center py-25"
        >
          {{ getUserUsername(userId) }}
          <b-button
            v-if="userId !== $store.state.auth.id"
            class="ml-auto"
            size="sm"
            variant="flat-danger"
            :title="`Remove ${getUserUsername(userId)} from owners`"
            :disabled="!loggedInUserIsAModelOwner"
            @click="removeOwner(userId)"
          >
            <feather-icon icon="TrashIcon" size="14" />
          </b-button>
          <b-button
            v-else
            class="ml-auto"
            size="sm"
            variant="flat-muted"
            title="You cannot remove yourself as a model owner"
            disabled
          >
            <feather-icon icon="TrashIcon" size="14" />
          </b-button>
        </b-list-group-item>
      </b-list-group>
      <div v-else>
        <p class="text-danger">
          An error occurred while loading the model owner list, or this model has no owners...
        </p>
      </div>
    </div>
    <div v-else>
      <div class="w-100 d-inline-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Model Owners...
          </h5>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import {
  computed,
  ref,
} from '@vue/composition-api'

export default {
  name: 'ManageModelOwners',
  components: {
    AtomSpinner,
  },
  props: {
    model: {
      type: String,
      required: false,
      default: '',
    },
    owners: {
      type: Array,
      required: false,
      default: () => [],
    },
    snapshot: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const loggedInUserIsAModelOwner = computed(() => store.state.model.owners && store.state.model.owners.includes(store.state.auth.id))
    const { modelId } = router.currentRoute.params

    const loadingFetchOwners = ref(false)
    const existingOwners = ref([])
    const fetchExistingOwners = () => {
      loadingFetchOwners.value = true
      coreService.modelApi.getModelOwners(modelId)
        .then(owners => {
          existingOwners.value = owners
        })
        .catch(error => {
          console.log(error)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch model owners',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loadingFetchOwners.value = false })
    }

    const newOwner = ref('')
    const loadingAddRemoveOwner = ref(false)
    const addOwner = () => {
      loadingAddRemoveOwner.value = true

      const payload = { user: newOwner.value, action: 'add' }
      coreService.put(`/v1/model/${modelId}/owners`, payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Owner added',
              icon: 'UserMinusIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to add owner',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loadingAddRemoveOwner.value = false
          fetchExistingOwners()
        })
    }
    const removeOwner = user => {
      loadingAddRemoveOwner.value = true

      const payload = { user, action: 'remove' }
      coreService.put(`/v1/model/${modelId}/owners`, payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Owner removed',
              icon: 'UserMinusIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to remove owner',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loadingAddRemoveOwner.value = false
          fetchExistingOwners()
        })
    }

    const onReset = () => {
      newOwner.value = ''
      existingOwners.value = []
      loadingAddRemoveOwner.value = false
      loadingFetchOwners.value = false
    }

    return {
      loggedInUserIsAModelOwner,
      newOwner,
      existingOwners,

      loadingAddRemoveOwner,
      loadingFetchOwners,

      fetchExistingOwners,
      addOwner,
      removeOwner,
      onReset,
    }
  },
}
</script>
