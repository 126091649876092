<template>
  <div class="d-none d-md-block">
    <b-row>
      <b-col>
        <card-model-workspace
          title="Specifications"
          :icon="['fas', 'clipboard-list']"
          :named-route="specifications"
        />
      </b-col>

      <b-col>
        <card-model-workspace
          title="Domain Model"
          :icon="['fas', 'bezier-curve']"
          :named-route="domainOntology"
        />
      </b-col>
      <b-col>
        <card-model-workspace
          title="Behaviour Trees"
          :icon="['fas', 'vector-square']"
          :named-route="behaviourTrees"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <card-model-workspace
          title="Issues"
          :icon="['fas', 'spinner']"
          :named-route="issues"
        />
      </b-col>

      <b-col>
        <card-model-workspace
          title="Test Cases"
          :icon="['fas', 'vials']"
          :named-route="testCases"
        />
      </b-col>

      <b-col>
        <card-model-workspace
          title="Releases"
          :icon="['fas', 'paper-plane']"
          :named-route="releases"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardModelWorkspace from '@/components/Cards/CardModelWorkspace.vue'
import router from '@/router'

export default {
  name: 'ModelDashboardCards',
  components: {
    CardModelWorkspace,
  },
  setup() {
    const { modelId } = router.currentRoute.params
    const specifications = ({ name: 'all_specifications_table', modelId })
    const domainEcosystem = ({ name: 'domain_ecosystem', modelId })
    const domainOntology = ({ name: 'domain_ontology', modelId })
    const behaviourTrees = ({ name: 'behaviour_trees', modelId })
    const issues = ({ name: 'app_issues', modelId })
    const testCases = ({ name: 'model_tests', modelId })
    const releases = ({ name: 'model_releases', modelId })

    return {
      specifications,
      domainEcosystem,
      domainOntology,
      behaviourTrees,
      issues,
      testCases,
      releases,
    }
  },
}
</script>
